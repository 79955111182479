import { Link } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`

export { StyledLink }

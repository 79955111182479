import React, { useState } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import MenuIcon from "@material-ui/icons/Menu"
import { StyledLink as PageLink } from "./link"
import ExternalLink from '@material-ui/core/Link';
import StakeLogo from "../images/logo-slim.svg"
import MediumIcon from "../images/icons/medium-icon.inline.svg"
import {
  Button,
  Drawer,
  ListSubheader,
  CssBaseline,
  Link,
} from "@material-ui/core"
import LanguageIcon from "@material-ui/icons/Language"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import GitHubIcon from "@material-ui/icons/GitHub"
import TwitterIcon from "@material-ui/icons/Twitter"
import LinkedInIcon from "@material-ui/icons/LinkedIn"


import CloseIcon from "@material-ui/icons/Close"
import { useI18next } from "gatsby-plugin-react-i18next"

// add the page links in here, I know it's a very hacky way to do this
const MOCK_PAGE_LINKS_ARRAY = [
  {
    label:"Service",
    link: "/service",
  },{
    label:"Media",
    link: "/media",
  },{
    label:"Company",
    link: "/company",
  },{
    label:"News",
    link: "/news",
  },{
    label:"Career",
    // link: "/career",
    external: "",
  },{
    label:"Contact",
    link: "/contact",
  }
]

const MOCK_PAGE_LINKS = {
  products: "/service#products",
  development: "/service#development",
  RnD: "/service#rd",
  consulting: "/service#consulting",

  media: "/media",

  vision: "/company#vision",
  // team: "/company#team",
  value: "/company#value",
  company: "/company#company",
  career: "/career",
  contact: "/contact",
  lang: "/",
}

const SNS_LINKS = {
  github: "https://github.com/staketechnologies",
  twitter: "https://twitter.com/staketech",
  linkedin: "https://www.linkedin.com/company/staked-technologies/",
  medium: "https://medium.com/stake-technologies",
}

const ChangeLanguageLink = () => {
  const {language,originalPath} = useI18next()
  const nextLanguage = language==="en" ? "ja" : "en";
  const languageMap = {
    "ja": "日本語",
    "en": "English"
  }
  return (
    <PageLink to={originalPath} language={nextLanguage}>
      <Button color="inherit">
      <ListItemIcon style={{color: "inherit", minWidth: "30px", height: "25px"}}>
        <LanguageIcon />
      </ListItemIcon>
      <ListItemText>
        <Typography>
        {languageMap[nextLanguage]}
        </Typography>
      </ListItemText>
      </Button>
    </PageLink>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbarRoot: {
      backgroundColor: "transparent",
      backdropFilter: "blur(7px)",
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    mobileMenu: {
      backgroundColor: "black",
      color: "white",
    },
    mobileMediaLinks: {
      backgroundColor: "black",
      padding: theme.spacing(2),
      alignContent: "center",
    },
    nestedItem: {
      paddingLeft: theme.spacing(4),
    },
  })
)
export default function Navbar(props: any) {
  const classes = useStyles()

  const [isMobileMenu, setMobileMenu] = useState(false)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setMobileMenu(open)
  }

  const mobileMenuId = "primary-menu-mobile"

  const renderMobileMenu = (
    <Drawer anchor="top" open={isMobileMenu} onClose={toggleDrawer(false)}>
      <List
        component="nav"
        className={classes.mobileMenu}
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            disableSticky={false}
            style={{ textAlign: "right" }}
          >
            <IconButton
              style={{ color: "white" }}
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </ListSubheader>
        }
      >
        {MOCK_PAGE_LINKS_ARRAY.map((item, index) =>
          !!item.link
            ?<PageLink to={item.link} key={index}>
              <ListItem button>
                <ListItemText primary={item.label} />
              </ListItem>
            </PageLink>
            :<Link target="_blank" color="inherit" rel="noopener noreferrer" href={item.external}><ListItem button>
                <ListItemText primary={item.label} />
              </ListItem>
            </Link>
        )}
        <ChangeLanguageLink/>
      </List>
      <div className={classes.mobileMediaLinks}>
        <Link target="_blank" rel="noopener noreferrer" href={SNS_LINKS.github}>
          <IconButton style={{ color: "white" }}>
            <GitHubIcon />
          </IconButton>
        </Link>

        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={SNS_LINKS.twitter}
        >
          <IconButton style={{ color: "white" }}>
            <TwitterIcon />
          </IconButton>
        </Link>

        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={SNS_LINKS.linkedin}
        >
          <IconButton style={{ color: "white" }}>
            <LinkedInIcon />
          </IconButton>
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={SNS_LINKS.medium}
        >
          <IconButton style={{ color: "white" }}>
            <MediumIcon width="0.75em" height="0.75em" viewBox="0 0 24 24" style={{fill:"currentcolor"}}/>
          </IconButton>
        </Link>
      </div>
    </Drawer>
  )

  return (
    <>
      <CssBaseline />
      <div className={classes.grow}>
        <AppBar position="static" className={classes.navbarRoot} style={props.isTopPage?{}:{backgroundColor: "white",color: "black"}}>
          <Toolbar>
            <PageLink
              to="/"
              style={{
                display: props.isTopPage ? "none" : "block",
                height: "90%",
                margin: "auto auto auto 0px",
              }}
            >
              <img src={StakeLogo} alt="logo" style={{ height: "100%" }} />
            </PageLink>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
            {/* <ServiceMenuButton isMobile={false} /> */}
            {MOCK_PAGE_LINKS_ARRAY.map((item,index) =>
              !!item.link
                ?<PageLink to={item.link} key={index}>
                  <Button color="inherit">{item.label}</Button>
                </PageLink>
                :<Link target="_blank" color="inherit" rel="noopener noreferrer" href={item.external}>
                  <Button color="inherit">{item.label}</Button>
                </Link>
            )}
            <ChangeLanguageLink/>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    </>
  )
}
